/** @format */

const FEED_CARD_TYPE = {
  BASIC: "basic",
  HIGHLIGHT: "highlight",
  PROMO: "promo",
  END_OF_FEED: "end_of_feed",
  PREVIEW_FEED: "preview_feed"
};

export default FEED_CARD_TYPE;
